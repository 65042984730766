<template>
    <div class="home-wrap">
        <div class="header">
            <div class="layout">
                <div class="logo"></div>
            </div>
        </div>
        <div class="main-con-wrap">
            <div class="layout">
                <div class="main-con">
                    <div class="info">
                        <div class="title">成就你的职业人生</div>
                        <div class="sub">ProsClub是一个以俱乐部的形式，让有共同志向的互联网职场前人和新人传递就业/创业的经验和价值，分享资讯与资源的平台。</div>
                        <div class="qrcode-wrap">
                            <div class="qrcode">
                                <img :src="qrcode"
                                     alt=""
                                     class="img">
                            </div>
                            <div class="txt">扫码下载APP</div>
                        </div>
                        <div class="btn-wrap">
                            <div class="btn apple"
                                 @click="downloadIos"></div>
                            <div class="btn android"
                                 @click="downloadAndroid"></div>
                        </div>
                    </div>
                    <div class="pic"><img class="img"
                             :src="pic1"
                             alt=""></div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="txt-con">
                版权所有：深圳市趣博仕信息科技有限公司 <div class="sp"></div>网站备案号：<a href="https://beian.miit.gov.cn"
                   target="_blank">粤ICP备2020110336号</a><br>
                客服邮箱：kefu@drfun.cn <div class="sp"></div>市场合作邮箱: market@drfun.cn <br>
                Copyright@2020-2022 Drfun
            </div>
        </div>

        <div class="download-tip"
             @click="showTip = false"
             v-if="showTip">
            <div class="txt"></div>
        </div>

    </div>
</template>

<script>
import { isIos, isWeixin } from '@/utils/index'
export default {
    name: 'Home',
    components: {},
    data () {
        return {
            pic1: require('@/assets/home/pic-1.png'),
            qrcode: require('@/assets/home/qrcode.png'),
            showTip: false,
        }
    },
    methods: {
        downloadIos () {
            if (isWeixin()) {
                this.showTip = true
                return
            }
            // return alert('IOS版暂未上线')
            location.href = 'https://testflight.apple.com/join/n1xeHYXA'
        },
        downloadAndroid () {
            if (isWeixin()) {
                this.showTip = true
                return
            }
            location.href = 'https://prosclub.cn/download/prosclub.apk'
        },
    },
}
</script>

<style lang="scss">
.home-wrap {
    .download-tip {
        background: rgba($color: #000000, $alpha: 0.7);
        position: fixed;
        z-index: 99;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        .txt {
            position: absolute;
            background-image: url("~@/assets/home/download-tip.png");
            width: 220px;
            height: 125px;
            background-size: 100% 100%;
            right: 20px;
            top: 10px;
        }
    }
    .layout {
        max-width: 1200px;
        margin: 0 auto;
    }
    .header {
        padding-top: 30px;
        .logo {
            background: url("~@/assets/home/logo.png");
            width: 266px;
            height: 52px;
            background-size: 100% 100%;
        }
    }
    .main-con {
        display: flex;
        padding-top: 92px;
        .info {
            flex: 1;
            .title {
                font-size: 52px;
                color: $text--color-main;
                line-height: 60px;
            }
            .sub {
                font-size: 26px;
                font-weight: 400;
                color: $text--color-base;
                line-height: 40px;
                margin-top: 31px;
            }
            .qrcode-wrap {
                margin-top: 33px;
                width: 156px;
                height: 197px;
                background: #ffffff;
                box-shadow: 0px 0px 9px 0px rgba(96, 96, 97, 0.13);
                .qrcode {
                    padding: 4px;
                    .img {
                        width: 100%;
                    }
                }
                .txt {
                    font-size: 14px;
                    font-weight: 500;
                    color: #9297b0;
                    line-height: 15px;
                    letter-spacing: 8px;
                    text-align: center;
                }
            }
            .btn-wrap {
                display: flex;
                align-items: center;
                margin-top: 60px;
                .btn {
                    width: 172px;
                    height: 53px;
                    background-size: 100% 100%;
                    cursor: pointer;

                    &.apple {
                        background-image: url("~@/assets/home/download-btn-apple.png");
                    }
                    &.android {
                        background-image: url("~@/assets/home/download-btn-android.png");
                    }
                    &:first-child {
                        margin-right: 15px;
                    }
                }
            }
        }
        .pic {
            margin-left: 100px;
            width: 533px;
            padding-top: 55px;
            .img {
                width: 100%;
            }
        }
    }

    .footer {
        background: #1d1d20;
        padding: 23px 0;
        margin-top: 180px;
        .txt-con {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            text-align: center;
            .sp {
                display: none;
            }
        }
    }
}
</style>

<style lang="scss">
@media only screen and (max-width: 600px) {
    .home-wrap {
        .layout {
            padding: 0 10px;
        }
        .header {
            padding-top: 20px;
            .logo {
                width: 153px;
                height: 30px;
            }
        }
        .main-con {
            padding-top: 30px;
            display: block;

            .info {
                .title {
                    font-size: 32px;
                    line-height: 40px;
                }
                .sub {
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 15px;
                }

                .qrcode-wrap {
                    display: none;
                }

                .btn-wrap {
                    margin-top: 30px;
                    justify-content: space-between;
                }
            }

            .pic {
                margin-left: 0px;
                width: auto;
                padding-top: 30px;
                .img {
                    width: 100%;
                }
            }
        }

        .footer {
            margin-top: 40px;
            .txt-con {
                font-size: 12px;
                .sp {
                    display: block;
                }
            }
        }
    }
}
</style>
